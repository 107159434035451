import React, { useState } from "react"
import { Link } from "gatsby"

const Accordion = item => {
  const [expand, setExpand] = useState(false)

  console.log(item)

  return (
    <div class="faq3_accordion">
      <div
        data-w-id="439ab0f8-22dd-827f-89fb-d98d5cfff80e"
        class="faq3_question"
        onClick={() => setExpand(!expand)}
      >
        <div class="text-size-medium text-weight-bold">
          {item.item.question}
        </div>
        <img
          src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db250f0e729562a_icon_chevron.svg"
          loading="lazy"
          alt=""
          class="faq3_icon"
          style={{ transform: expand ? "rotate(180deg)" : "" }}
        />
      </div>
      <div
        style={{ width: "100%", height: expand ? "" : "0" }}
        class="faq3_answer"
      >
        <div class="margin-bottom margin-small">
          <p>{item.item.answer}</p>
        </div>
      </div>
    </div>
  )
}

export default Accordion
