import React, { useState } from "react"
import { graphql, StaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import Accordion from "../components/Accordion"

import "./style.css"

//TODO: switch to staticQuery, get rid of comments, remove unnecessary components, export as draft template

const faq = [
  {
    question: "Hvor mye koster en nettside?",
    answer:
      "500 kr i måneden for full tilgang til tjenestene våre. Ingen oppstartskostnader eller skjulte tilleggsavgifter.",
  },
  {
    question: "Hva er inkludert?",
    answer:
      "Alt som trengs for å opprette og vedlikeholde en nettside, inkludert innholdsproduksjon, design, hosting, vedlikehold og mer.",
  },
  {
    question: "Må jeg lage alt innhold for min egen nettside?",
    answer:
      "Du trenger bare å oppgi noen setninger om deg selv eller din bedrift og hva du vil at nettsiden din skal vise, og vi tar oss av resten.",
  },
  {
    question: "Kan jeg gjøre endringer på nettsiden?",
    answer:
      "Selvfølgelig. Vi gjør eventuelle endringer og oppdateringer du trenger, og er tilgjengelig 7 dager i uken.",
  },
  {
    question: "Kan jeg ikke bare bruke Squarespace eller Wix?",
    answer:
      "Tjenesten vår passer best for folk som ikke ønsker å håndtere (eller lære) et komplisert verktøy. Vi takler alt som omhandler opprettelsen og vedlikeholdet av en nettside slik at du ikke trenger.",
  },
]

const BlogIndex = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title
  const [pricing, setPricing] = useState("yearly")

  return (
    <>
      {/* <Layout title={siteTitle}> */}
      <SEO
        title="Innovativ nettsideutvikling"
        keywords={[
          `Kurat`,
          `Nettsideutvikling`,
          `nettside`,
          `JAM-stack`,
          `Gatsby`,
          `Statisk nettsider`,
          `Rask nettside`,
        ]}
        description="En revolusjon innen nettsideutvikling"
      />
      <div class="page-wrapper-2">
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          fs-scrolldisable-element="smart-nav"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          class="navbar11_component w-nav"
        >
          <div class="navbar11_container">
            <a
              href="/"
              aria-current="page"
              class="navbar11_logo-link w-nav-brand w--current"
            >
              <img
                src="/images/Logo.svg"
                loading="lazy"
                alt=""
                class="navbar11_logo"
              />
            </a>
            <nav
              role="navigation"
              class="navbar11_menu is-page-height-tablet w-nav-menu"
            >
              <div class="navbar11_menu-buttons">
                <a
                  id="nav-cta"
                  href="https://3dgnd94kyje.typeform.com/to/BvWUBW7p"
                  target="_blank"
                  class="button is-small w-button"
                >
                  Kom i gang
                </a>
              </div>
            </nav>
          </div>
        </div>
        <main class="main-wrapper">
          <header class="section_header15">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="header15_component">
                    <div class="margin-bottom margin-xxlarge">
                      <div
                        data-w-id="af9da640-f7ab-f6c3-ab17-c596cfe0c9b3"
                        // style={{opacity:'0',  rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)}}
                        class="w-layout-grid header15_content-wrapper"
                      >
                        <div
                          id="w-node-af9da640-f7ab-f6c3-ab17-c596cfe0c9b4-da2955c3"
                          class="header15_content-left"
                        >
                          <h1>Profesjonell og rask nettside på 1-2-3</h1>
                          <div class="uui-ratings_component top16">
                            <div class="uui-avatar-group_component">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a958d51b2c1c49_avatar-group-01.png"
                                loading="lazy"
                                alt="User rating"
                                class="uui-avatar-group_item"
                              />
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a958798b2c1c47_avatar-group-02.png"
                                loading="lazy"
                                alt="User rating"
                                class="uui-avatar-group_item"
                              />
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a958fdbd2c1c4b_avatar-group-03.png"
                                loading="lazy"
                                alt="User rating"
                                class="uui-avatar-group_item"
                              />
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a958520b2c1c3e_avatar-group-04.png"
                                loading="lazy"
                                alt="User rating"
                                class="uui-avatar-group_item"
                              />
                              {/* <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a95876692c1c45_avatar-group-05.png"
                                loading="lazy"
                                alt="User rating"
                                class="uui-avatar-group_item"
                              /> */}
                            </div>
                            <div class="ratings_wrapper">
                              <div class="ratings_stars-wrapper">
                                <img
                                  src="https://assets.website-files.com/63b253637db25028cf2955e1/63b4f95364a958bd202c1c4d_stars.svg"
                                  loading="lazy"
                                  alt="5-star rating"
                                  class="uui-ratings_stars"
                                />
                                <div class="uui-ratings_number">4.8</div>
                              </div>
                              <div class="text-size-small text-weight-medium grey">
                                Anbefales av en rekke bedrifter
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="header15_content-right">
                          <div class="label-container">
                            <img
                              src="https://assets.website-files.com/63b253637db25028cf2955e1/63b262c85808d34cee58d9bb_zap-fast.svg"
                              loading="lazy"
                              width="16"
                              alt=""
                              class="icon-r4"
                            />
                            <p class="text-size-small label">
                              Oppsett på kun 5 minutter
                            </p>
                          </div>
                          <p class="text-size-medium">
                            Vi bygger og administrerer nettstedet ditt for deg
                            for en enkel fast månedlig avgift. Send oss noen få
                            detaljer, så har vi siden din klar om mindre enn en
                            uke.
                          </p>
                          <div class="margin-top margin-medium">
                            <div class="button-group">
                              <a
                                href="https://3dgnd94kyje.typeform.com/to/BvWUBW7p"
                                target="_blank"
                                class="button w-button"
                              >
                                Kom i gang for kun 500 kr
                              </a>
                              <a
                                href="#hiw-1"
                                class="button is-secondary w-button"
                              >
                                Mer informasjon
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="hero-image"
                      data-w-id="af9da640-f7ab-f6c3-ab17-c596cfe0c9c0"
                      // style="opacity:0;-webkit-transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 120px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                      // class="header15_image-wrapper"
                    >
                      <img
                        src="/images/hero.jpg"
                        loading="lazy"
                        width="100%"
                        // width="56"
                        alt=""
                        style={{ borderRadius: "20px" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <section id="hiw-1" class="section_layout225">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="w-layout-grid layout225_component">
                    <div class="layout225_image-wrapper placeholder">
                      <img
                        src="/images/Feature-2.jpg"
                        loading="lazy"
                        width="616"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 43vw, 42vw"
                        alt=""
                        class="image"
                      />
                    </div>
                    <div
                      id="w-node-_0788d072-8c98-dd93-b04a-c8c243e2b857-da2955c3"
                      class="layout225_content"
                    >
                      <div class="w-layout-grid layout225_item-list">
                        <div
                          id="w-node-_0788d072-8c98-dd93-b04a-c8c243e2b859-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _1">
                            <img
                              src="/images/full-service.svg"
                              loading="lazy"
                              width="12"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">Full service</h3>
                            </div>
                            <p>
                              Vi vil håndtere alle aspekter ved opprettelse og
                              vedlikehold av nettsider, inkludert
                              innholdsoppretting, design, utvikling og
                              vedlikehold.
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          id="w-node-_0788d072-8c98-dd93-b04a-c8c243e2b868-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _2">
                            <img
                              src="/images/simple.svg"
                              loading="lazy"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">
                                Enkelt og effektivt
                              </h3>
                            </div>
                            <p>
                              Tradisjonelle nettstedtjenester er enten for dyre
                              eller altfor komplekse, og ofte begge deler! Vi er
                              ingen av dem: vi gjør det utrolig enkelt å lage et
                              nettsted som ser bra ut for deg og din bedrift.
                              <br />
                            </p>
                          </div>
                        </div>
                        <div
                          id="w-node-_7281d04e-2a42-db12-83a1-a394f7096ac9-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _3">
                            <img
                              src="/images/content.svg"
                              loading="lazy"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">
                                Gratis innholdsproduksjon
                              </h3>
                            </div>
                            <p>
                              En rask skildring om deg selv og bedriften din er
                              alt vi trenger, og vi lager originalt innhold for
                              å fylle nettstedet ditt og optimalisere for
                              oppdagelse av søkemotorer.
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="hiw-2" class="section_layout225">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="w-layout-grid layout225_component">
                    <div
                      id="w-node-ffee9fd8-2a54-49d4-14c3-0968da2638f2-da2955c3"
                      class="layout225_content"
                    >
                      <div class="w-layout-grid layout225_item-list">
                        <div
                          id="w-node-ffee9fd8-2a54-49d4-14c3-0968da2638f4-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _1">
                            <img
                              src="/images/optimized.svg"
                              loading="lazy"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">
                                Optimalisert for deg
                              </h3>
                            </div>
                            <p>
                              Vi har designet dusinvis av nettsteder og bruker
                              beste praksis og læring som er bygget opp over år
                              til hver kunde.
                            </p>
                          </div>
                        </div>
                        <div
                          id="w-node-ffee9fd8-2a54-49d4-14c3-0968da2638fd-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _2">
                            <img
                              src="/images/domain.svg"
                              loading="lazy"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">
                                Egendefinerte domener inkludert
                              </h3>
                            </div>
                            <p>
                              Hvis du har (eller ønsker) et tilpasset domene
                              (dvs. www.dittnavn.com), tar vi hånd om det også.
                            </p>
                          </div>
                        </div>
                        <div
                          id="w-node-_48323d2f-f84e-9a26-6416-24ccb535d1d8-da2955c3"
                          class="layout225_item"
                        >
                          <div class="layout225_item-icon-wrapper _3">
                            <img
                              src="/images/locl.svg"
                              loading="lazy"
                              alt=""
                              class="icon-1x1-medium"
                            />
                          </div>
                          <div class="layout225_item-text-wrapper">
                            <div class="margin-bottom margin-xsmall">
                              <h3 class="heading-style-h5">
                                Ingen bindingstid eller skjulte avgifter
                              </h3>
                            </div>
                            <p>
                              Redd for å ombestemme deg? Null problem. Vi har
                              ingen bindingstid eller avmeldingskostnader.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="layout225_image-wrapper placeholder">
                      <img
                        src="/images/domain.jpg"
                        loading="lazy"
                        width="616"
                        sizes="(max-width: 479px) 100vw, (max-width: 767px) 90vw, (max-width: 991px) 43vw, 42vw"
                        alt=""
                        class="image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="gallery" class="section_gallery10">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="margin-bottom margin-xxlarge">
                    <div class="text-align-center">
                      <div class="max-width-large align-center">
                        <div class="margin-bottom margin-small">
                          <h2>Stilfullt og enkelt design</h2>
                        </div>
                        <p class="text-size-medium centered">
                          Vi tilbyr en rekke stilalternativer slik at du kan
                          velge det utseendet som er perfekt for deg. Vi lager
                          alt innholdet du trenger, og kan gjøre oppdateringer
                          når du måtte ønske det.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="gallery10_component">
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-1.png"
                        loading="lazy"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-2.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-3.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-4.png"
                        loading="lazy"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-5.png"
                        loading="lazy"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-6.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-7.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-8.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                    <a class="gallery10_lightbox-link w-inline-block w-lightbox">
                      <img
                        src="/images/example-9.png"
                        loading="lazy"
                        width="1600"
                        sizes="(max-width: 767px) 90vw, 28vw"
                        alt=""
                        class="gallery10_image"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="pricing" class="section_pricing7">
            <div class="padding-global-2">
              <div class="container-small">
                <div class="padding-section-large-3">
                  <div class="margin-bottom margin-large">
                    <div class="text-align-center">
                      <div class="max-width-large">
                        <div class="margin-bottom margin-small">
                          <h2 class="heading-7 centered">
                            Én pris. Mange fordeler.
                          </h2>
                        </div>
                        <p class="text-size-medium centered">
                          Vi håndterer hele ende-til-ende-tjenesten på
                          nettstedet ditt, slik at du ikke trenger å tenke på
                          det. Nettsidene våre er optimalisert med de nyeste
                          teknologiene for å sikre at ytelsen er på topp.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="max-width-medium align-center">
                    <div
                      data-current="Tab 2"
                      data-easing="ease"
                      data-duration-in="300"
                      data-duration-out="100"
                      class="pricing7_component w-tabs"
                    >
                      <div class="pricing7_tabs-menu w-tab-menu">
                        <a
                          data-w-tab="Tab 1"
                          class={`pricing7_tab-link w-inline-block w-tab-link ${pricing ===
                            "monthly" && "w--current"}`}
                          onClick={() => setPricing("monthly")}
                        >
                          <div>Månedlig betaling</div>
                        </a>
                        <a
                          data-w-tab="Tab 2"
                          class={`pricing7_tab-link w-inline-block w-tab-link ${pricing ===
                            "yearly" && "w--current"}`}
                          onClick={() => setPricing("yearly")}
                        >
                          <div class="save-tag">
                            <div>↑ Spar 1000 kr ↑</div>
                          </div>
                          <div>Årlig betaling</div>
                        </a>
                      </div>

                      <div class="w-tab-content">
                        {pricing === "monthly" && (
                          <div
                            data-w-tab="Tab 1"
                            class="pricing7_tab-pane w-tab-pane w--tab-active"
                          >
                            <div class="pricing5_plan">
                              <div class="pricing7_content">
                                <div class="heading-style-h5 hide">
                                  Én enkel pris
                                </div>
                                <div class="margin-vertical margin-xxsmall">
                                  <div class="heading-style-h1">
                                    500 kr
                                    <span class="heading-style-h4">/mnd</span>
                                  </div>
                                </div>
                                <div class="pricing5_divider"></div>
                                <div class="text-block-3">Inkluderer:</div>
                                <div class="pricing5_feature-list">
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00de-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset domene</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00e3-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Innholdsproduksjon</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00e8-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Hosting og vedlikehold</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00ed-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset design</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00f2-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Rask kundeservice</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00f7-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset mobil</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00fc-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Ubegrensede endringer</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee0101-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Livstidsoppdateringer</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee0106-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset din merkevare</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee010b-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>SEO-optimalisert</div>
                                  </div>
                                </div>
                                <a
                                  href="https://3dgnd94kyje.typeform.com/to/BvWUBW7p"
                                  target="_blank"
                                  class="button max-width-full w-button"
                                >
                                  Kom i gang
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                        {pricing === "yearly" && (
                          <div
                            data-w-tab="Tab 1"
                            class="pricing7_tab-pane w-tab-pane w--tab-active"
                          >
                            <div class="pricing5_plan">
                              <div class="pricing7_content">
                                <div class="heading-style-h5 hide">
                                  Én enkel pris
                                </div>
                                <div class="margin-vertical margin-xxsmall">
                                  <div class="heading-style-h1">
                                    5000 kr
                                    <span class="heading-style-h4">/år</span>
                                  </div>
                                </div>
                                <div class="pricing5_divider"></div>
                                <div class="text-block-3">Inkluderer:</div>
                                <div class="pricing5_feature-list">
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00de-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset domene</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00e3-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Innholdsproduksjon</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00e8-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Hosting og vedlikehold</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00ed-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset design</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00f2-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Rask kundeservice</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00f7-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset mobil</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee00fc-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Ubegrensede endringer</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee0101-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Livstidsoppdateringer</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee0106-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>Tilpasset din merkevare</div>
                                  </div>
                                  <div
                                    id="w-node-_85b6ad46-2767-d9ca-fea2-b7a0a7ee010b-da2955c3"
                                    class="pricing5_feature"
                                  >
                                    <div class="pricing5_icon-wrapper">
                                      <img
                                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b253637db25034732955e7_icon_check.svg"
                                        loading="lazy"
                                        alt=""
                                        class="icon-1x1-xsmall"
                                      />
                                    </div>
                                    <div>SEO-optimalisert</div>
                                  </div>
                                </div>
                                <a
                                  href="https://3dgnd94kyje.typeform.com/to/BvWUBW7p"
                                  target="_blank"
                                  class="button max-width-full w-button"
                                >
                                  Kom i gang
                                </a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="compare" class="section_pricing27 hide-tablet">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-3">
                  <div class="margin-bottom margin-large">
                    <div class="text-align-center">
                      <div class="max-width-large align-center">
                        <div class="margin-bottom margin-small">
                          <h2 class="heading-7 centered">
                            Sammenlikn oss med alternativer
                          </h2>
                        </div>
                        <p class="text-size-medium-2">
                          Ikke la disse andre tjenestene lure deg til å betale
                          mer for ting du ikke trenger.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    data-current="Tab 1"
                    data-easing="ease"
                    data-duration-in="300"
                    data-duration-out="100"
                    class="pricing27_component w-tabs"
                  >
                    <div class="pricing27_tabs-menu hide w-tab-menu">
                      <a
                        data-w-tab="Tab 1"
                        class="pricing27_tab-link w-inline-block w-tab-link w--current"
                      >
                        <div>Månedlig betaling</div>
                      </a>
                      <a
                        data-w-tab="Tab 2"
                        class="pricing27_tab-link w-inline-block w-tab-link"
                      >
                        <div>Årlig betaling</div>
                      </a>
                    </div>
                    <div class="pricing27_tabs-content w-tab-content">
                      <div
                        data-w-tab="Tab 1"
                        class="pricing27_tab-pane w-tab-pane w--tab-active"
                      >
                        <div class="pricing27_plans">
                          <div class="w-layout-grid pricing27_top-row mbottom">
                            <div class="pricing27_empty-space"></div>
                            <div class="pricing27_top-row-content highlight">
                              <div class="div-block pricing">
                                <img
                                  src="/images/Logo.svg"
                                  loading="lazy"
                                  height="48"
                                  alt=""
                                  class="navbar11_logo"
                                />
                                <div class="margin-vertical margin-xxsmall">
                                  <div class="heading-style-h5 highlight">
                                    500 kr
                                    <span class="heading-style-h5-2">/mnd</span>
                                  </div>
                                </div>
                                <div>Det er alt!</div>
                              </div>
                            </div>
                            <div class="pricing27_top-row-content">
                              <div class="div-block pricing">
                                <img
                                  src="https://assets.website-files.com/63b253637db25028cf2955e1/63f3f01c605c2a55dd622d98_squarespace-logo-horizontal-black.jpg"
                                  loading="lazy"
                                  height="48"
                                  sizes="100vw"
                                  srcset="
                                  https://assets.website-files.com/63b253637db25028cf2955e1/63f3f01c605c2a55dd622d98_squarespace-logo-horizontal-black-p-500.jpg  500w,
                                  https://assets.website-files.com/63b253637db25028cf2955e1/63f3f01c605c2a55dd622d98_squarespace-logo-horizontal-black-p-800.jpg  800w,
                                  https://assets.website-files.com/63b253637db25028cf2955e1/63f3f01c605c2a55dd622d98_squarespace-logo-horizontal-black.jpg       1000w
                                "
                                  alt=""
                                  class="navbar11_logo"
                                />
                                <div class="margin-vertical margin-xxsmall">
                                  <div class="heading-style-h5">250 kr/mnd</div>
                                </div>
                                <div>Og du gjør hele jobben! 🤔</div>
                              </div>
                            </div>
                            <div class="pricing27_top-row-content">
                              <div class="div-block pricing">
                                <div class="text-block-5 height-48">
                                  Markedsbyrå
                                </div>
                                <div class="margin-vertical margin-xxsmall">
                                  <div class="heading-style-h5">
                                    50 000 kr forhåndsbetaling
                                    <span class="heading-style-h5-2"></span>
                                  </div>
                                </div>
                                <div>Pluss 500 kr/mnd 😱</div>
                              </div>
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row">
                            <div
                              id="w-node-_5a1be757-041f-c48b-6d0c-4b179e6de7f7-da2955c3"
                              class="pricing27_feature"
                            >
                              <div>Gratis innholdsproduksjon</div>
                            </div>
                            <div class="pricing27_row-content highlight top">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43e02f8f1ed617a7da65a_check-circle-bw.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                              <div>
                                Du leverer og administrerer alt innholdet
                              </div>
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f44406605c2ad2b3695ff6_circle-check-lightblue.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row">
                            <div
                              id="w-node-_5a1be757-041f-c48b-6d0c-4b179e6de803-da2955c3"
                              class="pricing27_feature"
                            >
                              <div>Gratis tilpasset domene for alltid</div>
                            </div>
                            <div class="pricing27_row-content highlight">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43e02f8f1ed617a7da65a_check-circle-bw.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                              <div>Kun ett år gratis</div>
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                              <div>Ekstra kostnad</div>
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row">
                            <div
                              id="w-node-_5a1be757-041f-c48b-6d0c-4b179e6de811-da2955c3"
                              class="pricing27_feature"
                            >
                              <div>Gratis tilpasset design</div>
                            </div>
                            <div class="pricing27_row-content highlight">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43e02f8f1ed617a7da65a_check-circle-bw.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                              <div>
                                5000 kr for en designer må du finne selv
                              </div>
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                              <div>
                                50 000 kr forhåndskostnad, inkonsekvent kvalitet
                              </div>
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row">
                            <div class="pricing27_feature">
                              <div>Ubegrenset endringer</div>
                            </div>
                            <div class="pricing27_row-content highlight">
                              <div>Send oss en epost</div>
                            </div>
                            <div class="pricing27_row-content">
                              <div>Gjør endringer selv</div>
                            </div>
                            <div class="pricing27_row-content">
                              <div>Send en epost</div>
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row">
                            <div
                              id="w-node-_5a1be757-041f-c48b-6d0c-4b179e6de836-da2955c3"
                              class="pricing27_feature"
                            >
                              <div>Dedikert kundeservice</div>
                            </div>
                            <div class="pricing27_row-content highlight">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43e02f8f1ed617a7da65a_check-circle-bw.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <div>Automatisert, lange ventetider</div>
                            </div>
                            <div class="pricing27_row-content">
                              <div>Kommuniser via prosjektleder</div>
                            </div>
                          </div>
                          <div class="w-layout-grid pricing27_row last">
                            <div class="pricing27_feature">
                              <div>100% fornøydgaranti</div>
                            </div>
                            <div class="pricing27_row-content highlight bottom">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43e02f8f1ed617a7da65a_check-circle-bw.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                            <div class="pricing27_row-content">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63f43f94275e81d35758da3a_x-circle-lightred.svg"
                                loading="lazy"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="testimonials" class="section_testimonial28">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="margin-bottom margin-xxlarge">
                    <div class="max-width-large">
                      <div class="margin-bottom margin-small">
                        <h2>Kunder elsker oss</h2>
                      </div>
                    </div>
                  </div>
                  <div
                    data-delay="4000"
                    data-animation="slide"
                    class="testimonial28_component w-slider"
                    data-autoplay="false"
                    data-easing="ease"
                    data-hide-arrows="false"
                    data-disable-swipe="false"
                    data-autoplay-limit="0"
                    data-nav-spacing="3"
                    data-duration="500"
                    data-infinite="false"
                  >
                    <div class="testimonial28_mask w-slider-mask">
                      <div class="testimonial28_slide w-slide">
                        <div class="testimonial28_content">
                          <div class="testimonial28_rating-wrapper">
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="margin-bottom margin-small">
                            <div class="text-size-medium">
                              Noen ganger er den åpenbare løsningen den beste
                              løsningen. Kunne ikke vært mer fornøyd med den nye
                              nettsiden, og det er alt takket være{" "}
                              <strong>Kurat</strong>.
                            </div>
                          </div>
                          <div class="testimonial28_client">
                            <div class="testimonial28_client-image-wrapper">
                              <img
                                src="/images/Logo-okonomibloggen.png"
                                loading="lazy"
                                width="56"
                                alt=""
                                class="testimonial28_customer-image"
                              />
                            </div>
                            <div class="testimonial28_client-info">
                              <p class="text-weight-semibold">Økonomibloggen</p>
                              <p>Norsk finansblogg</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="testimonial28_slide w-slide">
                        <div class="testimonial28_content">
                          <div class="testimonial28_rating-wrapper">
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="margin-bottom margin-small">
                            <div class="text-size-medium">
                              Finnes ikke en rimeligere og enklere løsning for å
                              opprettholde en nettside. <strong>Kurat</strong>{" "}
                              fikser alt! Perfekt for kunstnettsiden min.
                            </div>
                          </div>
                          <div class="testimonial28_client">
                            <div class="testimonial28_client-image-wrapper">
                              <img
                                src="/images/Logo-lwe.png"
                                loading="lazy"
                                width="56"
                                alt=""
                                class="testimonial28_customer-image"
                              />
                            </div>
                            <div class="testimonial28_client-info">
                              <p class="text-weight-semibold">LWE Art</p>
                              <p>Kunstner</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="testimonial28_slide w-slide">
                        <div class="testimonial28_content">
                          <div class="testimonial28_rating-wrapper">
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="margin-bottom margin-small">
                            <div class="text-size-medium">
                              Sometimes the obvious solution is the best
                              solution. Couldn&#x27;t be happier with my new
                              website and it&#x27;s all thanks to
                              FiftyDollarWebsite.com.
                            </div>
                          </div>
                          <div class="testimonial28_client">
                            <div class="testimonial28_client-image-wrapper">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b5b4a9936aaad3c735ae9a_jannick.jpg"
                                loading="lazy"
                                alt=""
                                class="testimonial28_customer-image"
                              />
                            </div>
                            <div class="testimonial28_client-info">
                              <p class="text-weight-semibold">
                                Jannick Malling
                              </p>
                              <p>Co-CEO, Public.com</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="testimonial28_slide w-slide">
                        <div class="testimonial28_content">
                          <div class="testimonial28_rating-wrapper">
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                            <div class="testimonial28_rating-icon w-embed">
                              <svg
                                width="100%"
                                viewBox="0 0 18 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.16379 0.551109C8.47316 -0.183704 9.52684 -0.183703 9.83621 0.551111L11.6621 4.88811C11.7926 5.19789 12.0875 5.40955 12.426 5.43636L17.1654 5.81173C17.9684 5.87533 18.294 6.86532 17.6822 7.38306L14.0713 10.4388C13.8134 10.6571 13.7007 10.9996 13.7795 11.3259L14.8827 15.8949C15.0696 16.669 14.2172 17.2809 13.5297 16.8661L9.47208 14.4176C9.18225 14.2427 8.81775 14.2427 8.52793 14.4176L4.47029 16.8661C3.7828 17.2809 2.93036 16.669 3.11727 15.8949L4.22048 11.3259C4.29928 10.9996 4.18664 10.6571 3.92873 10.4388L0.317756 7.38306C-0.294046 6.86532 0.0315611 5.87533 0.834562 5.81173L5.57402 5.43636C5.91255 5.40955 6.20744 5.19789 6.33786 4.88811L8.16379 0.551109Z"
                                  fill="currentColor"
                                />
                              </svg>
                            </div>
                          </div>
                          <div class="margin-bottom margin-small">
                            <div class="text-size-medium">
                              Great. I love it.
                            </div>
                          </div>
                          <div class="testimonial28_client">
                            <div class="testimonial28_client-image-wrapper">
                              <img
                                src="https://assets.website-files.com/63b253637db25028cf2955e1/63b5b4211690bfd42520717d_leif.jpg"
                                loading="lazy"
                                alt=""
                                class="testimonial28_customer-image"
                              />
                            </div>
                            <div class="testimonial28_client-info">
                              <p class="text-weight-semibold">Leif Abraham</p>
                              <p>Co-CEO, Public.com</p>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                    {/* <div class="testimonial28_arrow is-left w-slider-arrow-left">
                      <img
                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b25cee2091f122c16fc5fe_arrow-left.svg"
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div class="testimonial28_arrow w-slider-arrow-right">
                      <img
                        src="https://assets.website-files.com/63b253637db25028cf2955e1/63b25cee5808d3a322588710_arrow-right.svg"
                        loading="lazy"
                        alt=""
                      />
                    </div>
                    <div class="testimonial28_slide-nav w-slider-nav w-slider-nav-invert w-round"></div> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="faq" class="section_faq3">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large no-stroke">
                  <div class="w-layout-grid faq3_component">
                    <div class="faq3_content">
                      <div class="margin-bottom margin-small">
                        <h2>Spørsmål og svar</h2>
                      </div>
                      <p class="text-size-medium">
                        Hvis du fortsatt har spørsmål, ta kontakt via e-post, så
                        skal vi gjøre vårt beste for å hjelpe deg.
                      </p>
                      <div class="margin-top margin-medium">
                        <a
                          href="mailto:vegard@kurat.no"
                          class="button is-secondary w-button"
                        >
                          Kontakt oss
                        </a>
                      </div>
                    </div>
                    <div class="faq3_list">
                      {faq.map(item => (
                        <Accordion item={item} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section id="cta-large" class="section_cta51">
            <div class="padding-global-2">
              <div class="container-large">
                <div class="padding-section-large-2">
                  <div class="w-layout-grid cta51_component">
                    <div class="cta51_card colored">
                      <div class="text-align-center">
                        <div class="max-width-large">
                          <div class="margin-bottom">
                            <h2>
                              Klar til å oppgradere din
                              <br />
                              synlighet på nett?
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div class="margin-top margin-medium">
                        <div class="button-group is-center">
                          <a
                            id="footer-cta"
                            href="https://3dgnd94kyje.typeform.com/to/BvWUBW7p"
                            target="_blank"
                            class="button is-secondary w-button"
                          >
                            Kom i gang på mindre enn 5 minutter
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer id="footer" class="footer7_component">
            <div class="padding-global">
              <div class="container-large">
                <div class="padding-vertical padding-xxlarge">
                  <div class="padding-bottom padding-xlarge">
                    <div id="footer" class="footer7_top-wrapper">
                      <a
                        href="#"
                        id="w-node-a8cd22d9-758b-2668-2e54-d4375ebe6249-da2955c3"
                        class="footer7_logo-link w-nav-brand"
                      >
                        <img src="/images/Logo.svg" loading="lazy" alt="" />
                      </a>
                    </div>
                  </div>
                  <div class="line-divider"></div>
                  <div class="padding-top padding-medium">
                    <div class="footer7_bottom-wrapper">
                      <div class="footer7_credit-text">
                        © 2023 Kurat. All rights reserved.
                      </div>
                      {/* <div class="w-layout-grid footer7_legal-list hide">
                        <a href="#" class="footer7_legal-link">
                          Privacy Policy
                        </a>
                        <a href="#" class="footer7_legal-link">
                          Terms of Service
                        </a>
                        <a href="#" class="footer7_legal-link">
                          Cookies Settings
                        </a>
                      </div>
                      <a
                        href="https://blog.fiftydollarwebsite.com/"
                        target="_blank"
                        class="footer-link"
                      >
                        Blog
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
      {/* </Layout> */}
    </>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    raskere: file(relativePath: { eq: "Icons-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skalering: file(relativePath: { eq: "Icons-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gatsby: file(relativePath: { eq: "Gatsby_Logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    nextjs: file(relativePath: { eq: "nextjs-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    netlify: file(relativePath: { eq: "netlify-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    strapi: file(relativePath: { eq: "strapi-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    react: file(relativePath: { eq: "react-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    trafikkbegrensning: file(relativePath: { eq: "Icons-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bygging: file(relativePath: { eq: "Icons-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vedlikehold: file(relativePath: { eq: "Icons-07.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sikkerhet: file(relativePath: { eq: "Icons-05.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    skylagring: file(relativePath: { eq: "Icons-06.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    prosjekter: file(relativePath: { eq: "Icons-08.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
